$primary:       #C1D37F;
$secondary:     #5E6973;
$success:       #6ABEA7;
$info:          #BDFFFD;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
$dark:          #664E4C;


$sizes: (
  10:10%,
  20:20%,
  30:30%,
  40:50%,
  60:60%,
  70:70%,
  80:80%,
  90:90%
);


@import "../node_modules/bootstrap/scss/bootstrap";



